<script lang="ts">
    import { twMerge } from 'tailwind-merge';
    import LogoAllibee from '$lib/assets/logo/allibee.svg?component';

    export let color: 'BLACK' | 'GREEN' = 'BLACK';

    let fontClass = 'text-black';

    $: {
        if (color === 'GREEN') {
            fontClass = 'text-primary-300';
        } else {
            fontClass = 'text-black';
        }
    }
</script>

<span>
    <LogoAllibee {...$$restProps} class={twMerge(fontClass, $$props.class)} />
</span>
